import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,

  isAffiliated: true,

  rivalName: "thisisvegas",
  casinoId: 11,
  GA_TRACKING: "G-4KHHJTVYLZ",

  rivalChatGroupName: "thisisvegas",
  prettyName: "This is Vegas",
  contactEmail: "support@thisisvegas.com",
  docsEmail: "documents@thisisvegas.email",

  publicPromotions: [
    {id: 'PARADISE8_1', name: "deposit", state: "eligible", details: "welcome", imgPath: "assets/brands/thisisvegas/promo.png"},
    {id: 2, name: "cashbackInsurance", state: "eligible", details: "welcome", imgPath: "assets/brands/thisisvegas/promo.png"},
    {id: 'PARADISE8_3', name: "nextDayCashback", state: "eligible", details: "weekly", imgPath: "assets/brands/thisisvegas/promo.png"},
    {id: 'PARADISE8_4', name: "loyalty", state: "eligible", details: "weekly", imgPath: "assets/brands/thisisvegas/promo.png"}
  ],

  legalData: {
    withdrawalTime: 12,
    affTerms: true
  },

  //SEO
  metaDescription: "Join Vegas Online at This is Vegas Casino and play real cash games, the best Vegas slots and live dealer tables. Get top casino bonuses, no deposit bonus, fast payouts, and secure transactions in bitcoin (BTC) too! Play This is Vegas Casino and join to our VIP online casino Room!",
  logoName: "thisisvegas-online-casino-logo.png",
  logoAltText: "This Is Vegas Casino official logo, featuring bold blue and red text with a starburst design, representing an exciting online casino offering a Las Vegas-style gaming experience with slots, table games, and live dealer options. Play securely and enjoy thrilling online gambling at This Is Vegas Casino."


};


